<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card title="All Organizations">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :to="{name: 'add-organizations'}"
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Add Organization</span>
                </b-button>
              </div>
            </b-form-group>

          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="filteredRows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.field === 'buttons'">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-1"
                  :to="{name: 'update-organizations', params: { id: props.row.id }}"
                ><feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />View Details</b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                ><feather-icon
                  icon="UsersIcon"
                  class="mr-50"
                />Manage Members</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCard, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: null,
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Action',
          field: 'buttons',
          sortable: false,
        },
      ],
      selectedRow: null,
      organizations: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filteredRows() {
      if (!this.filter) return this.organizations
      return this.organizations.filter(organization => organization.type.toLowerCase() === this.filter)
    },
  },
  methods: {
    prependHttps(url) {
      if (!url) return ''
      if (url.startsWith('http')) return url
      return `https://${url}`
    },
  },
  apollo: {
    organizations: {
      query() {
        return gql`query mentor_organizations {
          users_associationtable(where: {user_id: {_eq: ${getUserData().id}}}) {
            id
            organization_id
            program_id
            role
            designation
            users_organizationtable {
              title
              type
            }
          }
        }`
      },
      update: data => {
        const userData = getUserData()
        userData.associatedOrgDetails = data.users_associationtable
        localStorage.setItem('userData', JSON.stringify(userData))
        // Filter [-mentor]
        return data.users_associationtable.filter(e => !(e.role && e.role.toLowerCase() === 'mentor'))
          .map(organization => ({
            ...organization,
            ...organization.users_organizationtable,
          }))
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
